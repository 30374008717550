:root{
  --background: #111314; 
  --text: #DCDCDC;
  
}

[data-theme="dark"]{ 
  --background: #DCDCDC; 
  --text: #242424;
}

* { 
  transition: all 0.3s ease-in-out;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face{ 
  font-family: 'HelveticaAll'; 
  src: local('Helvetica'),
  url('./fonts/Helvetica.ttf') format('truetype');
  font-weight: 500; 
}

/* @font-face{ 
  font-family: 'Times New Roman'; 
  src: local('Times New Roman'),
  url('./fonts/Times.otf') format('truetype');
  font-weight: 500; 
} */